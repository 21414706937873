import {parseError, useMutation, useQuery} from 'admin/lib/apollo'
import {GET_PAYMENT_DETAIL, CREATE_PAYMENT_DETAIL, UPDATE_PAYMENT_DETAIL} from 'admin/gql/PaymentDetail.gql'
import mutateWith from './mutateWith'
import clearCache from './clearCache'

export default (organisationId, paymentDetailId) => {
  const {data: {paymentDetail} = {}, error: getError} = useQuery(GET_PAYMENT_DETAIL, {
    variables: {organisationId, paymentDetailId},
    skip: !paymentDetailId,
    errorPolicy: 'all'
  })

  const [create, {error: createError}] = useMutation(CREATE_PAYMENT_DETAIL, {
    variables: {organisationId},
    errorPolicy: 'all',
    update: cache => clearCache({organisation: {id: organisationId}})(cache)
  })

  const [update, {error: updateError}] = useMutation(UPDATE_PAYMENT_DETAIL, {
    variables: {organisationId, paymentDetailId},
    errorPolicy: 'all',
  })

  return {
    paymentDetail,
    errors: parseError(getError || createError || updateError),
    create: mutateWith(create, 'Payment detail created'),
    update: mutateWith(update, 'Payment detail updated'),
  }
}
