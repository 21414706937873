import {gql} from '@apollo/client'

export const GET_PAYMENT_DETAIL = gql`
  query GetPaymentDetail($organisationId: ID!, $paymentDetailId: ID!) {
    paymentDetail(organisationId: $organisationId, id: $paymentDetailId) {
      id
      contentWithPlaceholders
      name
    }
  }
`

export const CREATE_PAYMENT_DETAIL = gql`
  mutation CreatePaymentDetail($organisationId: ID!, $contentWithPlaceholders: String, $name: String) {
    paymentDetail(organisationId: $organisationId, contentWithPlaceholders: $contentWithPlaceholders, name: $name) {
      id
      contentWithPlaceholders
      name
    }
  }
`

export const UPDATE_PAYMENT_DETAIL = gql`
  mutation UpdatePaymentDetail($organisationId: ID!, $id: ID!, $contentWithPlaceholders: String, $name: String) {
    paymentDetail(organisationId: $organisationId, id: $id, contentWithPlaceholders: $contentWithPlaceholders, name: $name) {
      id
      contentWithPlaceholders
      name
    }
  }
`