import React, {Fragment, useState} from 'react'
import Dropdown from 'admin/components/ui/Dropdown'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

// options = [{id, name}]
export default ({id, buttonText, onChange, errors, dataField, dataQuery, className}) => {
  const {organisationID} = useParams()

  const {data} = useQuery(dataQuery, {variables: {organisationId: organisationID}})
  const options = data && data[dataField] ? data[dataField].collection : []

  const [searchText, setSearchText] = useState('')

  const handleSearch = e => {
    setSearchText(e.target.value)
  }

  const handleSelect = (e, option) => {
    e.preventDefault()
    onChange(option)
  }

  return (
    <Fragment>
      <Dropdown id={id} className={`field${errors ? ' mb-0' : ''}`} isOpen={searchText.length}>
        <a className={`button${errors ? ' is-danger is-outlined' : ''} ${className}`}>
          <span>{buttonText}</span>
          <span className='icon'>
            <i className='fa fa-caret-down'></i>
          </span>
        </a>
        {options.length > 10 ? (
          <div className='field'>
            <div className='control'>
              <input type='text' className='input is-small' onChange={handleSearch} placeholder='Search' />
            </div>
          </div>
        ) : null}
        {options.map(option => {
          if (!option || option.name.search(new RegExp(searchText, 'i')) < 0) return
          return (
            <a key={option.id} onClick={e => handleSelect(e, option)}>
              {option.name}
            </a>
          )
        })}
      </Dropdown>
      {errors && <span className={`help${errors ? ' is-danger' : ''}`}>{errors.join(', ')}</span>}
    </Fragment>
  )
}
