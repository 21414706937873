import React from 'react'
import {useParams, useHistory} from 'react-router-dom'
import useEffect from 'admin/hooks/useEffect'
import usePaymentDetail from 'admin/hooks/usePaymentDetail'
import useDocument from 'admin/hooks/useDocument'
import useStateObject from 'admin/hooks/useStateObject'
import InputField from 'admin/components/ui/InputField'
import Modal from 'admin/components/ui/Modal'

export default () => {
  const history = useHistory()
  const {organisationID} = useParams()
  const {paymentDetail, errors, create} = usePaymentDetail(organisationID)
  const [form, setForm] = useStateObject(paymentDetail)

  useDocument({title: 'Add payment detail'})

  useEffect(() => setForm(paymentDetail), [paymentDetail], false)

  const handleCreate = e => {
    e.preventDefault()
    create(form, history.goBack)
  }

  return (
    <Modal onClose={history.goBack} isOpen={true}>
      <section className='section has-background-light'>
        <div className='container'>
          <div className='columns  is-vcentered is-mobile'>
            <div className='is-5 column'>
              <h3 className='title is-marginless'>Add payment detail</h3>
            </div>
            <div className='is-7 column buttons is-right'>
              <button className='button is-success' onClick={handleCreate}>
                Add
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          <InputField
            type='text'
            name='name'
            label='Name'
            value={form.name || ''}
            onChange={setForm}
            errors={errors.name}
          />
          <InputField
            type='textarea'
            name='contentWithPlaceholders'
            label='Content'
            value={form.contentWithPlaceholders || ''}
            onChange={setForm}
            errors={errors.contentWithPlaceholders}
            rows={6}
          />
        </div>
      </section>
    </Modal>
  )
}
