import {gql} from 'admin/lib/apollo'

export const GET_ACCOUNT = gql`
  query GetAccount($organisationId: ID!, $accountId: ID!) {
    account(organisationId: $organisationId, id: $accountId) {
      id
      displayName @client
      name
      physicalAddress
      postalAddress
      contactFirstName
      contactLastName
      contactEmail
      billingEmail
      contactPhone
      customerNumber
      groups {
        id
        colour
        name
      }
      paymentDetail {
        id
        name
      }
      state
      isSuspended
      warnWhenOverdue
      xeroLink
      balance
      overdueSince
      paymentFrequencyInDays
    }
  }
`

export const CREATE_ACCOUNT = gql`
  mutation CreateOrUpdateAccount($organisationId: ID!, $name: String) {
    account(organisationId: $organisationId, name: $name) {
      id
      displayName @client
      name
      physicalAddress
      postalAddress
      contactFirstName
      contactLastName
      contactEmail
      billingEmail
      contactPhone
      customerNumber
      groups {
        id
        colour
        name
      }
      paymentDetail {
        id
        name
      }
      state
      isSuspended
      warnWhenOverdue
      xeroLink
      balance
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation CreateOrUpdateAccount(
    $organisationId: ID!
    $accountId: ID!
    $name: String
    $postalAddress: String
    $physicalAddress: String
    $contactFirstName: String
    $contactLastName: String
    $contactPhone: String
    $contactEmail: String
    $billingEmail: String
    $notes: String
    $customerNumber: String
    $groups: JSON
    $state: String
    $isSuspended: Boolean
    $warnWhenOverdue: Boolean
    $paymentDetailId: ID
  ) {
    account(
      organisationId: $organisationId
      id: $accountId
      name: $name
      postalAddress: $postalAddress
      physicalAddress: $physicalAddress
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactPhone: $contactPhone
      contactEmail: $contactEmail
      billingEmail: $billingEmail
      customerNumber: $customerNumber
      groups: $groups
      state: $state
      isSuspended: $isSuspended
      warnWhenOverdue: $warnWhenOverdue
      notes: $notes
      paymentDetailId: $paymentDetailId
    ) {
      id
      displayName @client
      name
      physicalAddress
      postalAddress
      contactFirstName
      contactLastName
      contactEmail
      billingEmail
      contactPhone
      customerNumber
      groups {
        id
        colour
        name
      }
      paymentDetail {
        id
        name
      }
      state
      isSuspended
      warnWhenOverdue
      xeroLink
      balance
      overdueSince
    }
  }
`
