import React, {Fragment, useState} from 'react'
import {isEmpty} from 'lodash'
import {useParams} from 'react-router-dom'
import {gql} from 'admin/lib/apollo'
import useOrganisation from 'admin/hooks/useOrganisation'
import useStateObject from 'admin/hooks/useStateObject'
import useEffect from 'admin/hooks/useEffect'
import GroupSelect from 'admin/components/ui/GroupSelect'
import EditField from 'admin/components/ui/EditField'
import ShowField from 'admin/components/ui/ShowField'
import SelectField from 'admin/components/ui/SelectField'

const PAYMENT_DETAIL_QUERY = gql`
  query GetPaymentDetailsForPaymentDetailField($organisationId: ID!) {
    paymentDetails(organisationId: $organisationId) {
      collection {
        id
        name
      }
    }
  }
`

export default ({account, errors, breadcrumbs, onCancel, onSave}) => {
  const {organisationID} = useParams()
  const {organisation} = useOrganisation(organisationID)
  const [form, setForm, resetForm] = useStateObject({})
  const [selectedGroupIds, setSelectedGroupIds] = useState([])
  const [deselectedGroupIds, setDeselectedGroupIds] = useState([])

  useEffect(() => {
    setForm(account)
    setSelectedGroupIds(account.groups.map(group => group.id))
  }, [account])

  if (isEmpty(account)) return null

  const handleRemoveGroup = ({id}) => {
    setSelectedGroupIds(selectedGroupIds.filter(groupId => String(groupId) !== String(id)))
    setDeselectedGroupIds([...deselectedGroupIds, id])
  }

  const handleAddGroup = ({id}) => {
    setSelectedGroupIds([...selectedGroupIds, id])
    setDeselectedGroupIds(deselectedGroupIds.filter(groupId => String(groupId) !== String(id)))
  }

  const handlePaymentDetailSelection = paymentDetail => {
    setForm({paymentDetail, paymentDetailId: paymentDetail.id})
  }

  const handleSave = () => {
    onSave({
      ...form,
      groups: [...selectedGroupIds.map(id => ({id})), ...deselectedGroupIds.map(id => ({id, _destroy: true}))]
    })
  }

  const handleCancel = () => {
    resetForm(account)
    setSelectedGroupIds(account.groups.map(group => group.id))
    setDeselectedGroupIds([])
    onCancel()
  }

  return (
    <Fragment>
      <div className='columns is-vcentered is-mobile'>
        <div className='column'>{breadcrumbs}</div>
        <div className='column buttons is-right'>
          <button onClick={handleCancel} className='button is-text is-decorationless'>
            Cancel
          </button>
          <button onClick={handleSave} className='button is-primary'>
            Save
          </button>
        </div>
      </div>

      <div className='columns is-variable is-7-desktop'>
        <div className='column'>
          <EditField name='name' label='Account name' value={form.name} errors={errors.name} onChange={setForm} />

          <EditField
            className='mb-1'
            onChange={setForm}
            values={{contactFirstName: form.contactFirstName, contactLastName: form.contactLastName}}
            errors={{contactFirstName: errors.contactFirstName, contactLastName: errors.contactLastName}}
          />

          <div className='field is-grouped'>
            <div className='control is-expanded'>
              <EditField
                name='physicalAddress'
                label='Physical address'
                className='mb-1'
                type='address'
                value={form.physicalAddress}
                errors={errors.physicalAddress}
                onChange={setForm}
              />
            </div>
            <div className='control is-expanded'>
              <EditField
                name='postalAddress'
                label='Postal address'
                className='mb-1'
                type='address'
                value={form.postalAddress}
                errors={errors.postalAddress}
                onChange={setForm}
              />
            </div>
          </div>

          <EditField
            name='contactPhone'
            label='Contact phone'
            className='mb-1'
            type='tel'
            value={form.contactPhone}
            errors={errors.contactPhone}
            onChange={setForm}
          />
          <EditField
            name='contactEmail'
            label='Contact email'
            className='mb-1'
            type='email'
            value={form.contactEmail}
            errors={errors.contactEmail}
            onChange={setForm}
          />
          <EditField
            name='billingEmail'
            label='Billing email'
            className='mb-1'
            type='email'
            value={form.billingEmail}
            errors={errors.billingEmail}
            onChange={setForm}
          />
        </div>

        <div className='column'>
          <ShowField label='Groups'>
            <GroupSelect
              selectedIds={selectedGroupIds}
              onSelect={handleAddGroup}
              onUnselect={handleRemoveGroup}
              buttonText='Add to group'
              type='custom'
            />
          </ShowField>
          {organisation.sms_enabled ? (
            <ShowField label='Notifications'>
              <EditField
                name='warnWhenOverdue'
                checked={!!form.warnWhenOverdue}
                label='Send SMS warnings when overdue'
                type='checkbox'
                errors={errors.warnWhenOverdue}
                onChange={setForm}
              />
            </ShowField>
          ) : null}
          <EditField
            name='customerNumber'
            label='Account number'
            value={form.customerNumber}
            errors={errors.customerNumber}
            onChange={setForm}
          />
          <ShowField label='Payment detail'>
            <SelectField
              id='payment-detail-select'
              buttonText={form.paymentDetail?.name || 'Select payment detail'}
              errors={errors.paymentDetailId}
              onChange={handlePaymentDetailSelection}
              dataQuery={PAYMENT_DETAIL_QUERY}
              dataField='paymentDetails'
              className='is-small'
            />
          </ShowField>
        </div>
      </div>
    </Fragment>
  )
}
