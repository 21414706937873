import React, {Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'
import {pluralise} from 'admin/lib/format'
import {isEmpty} from 'lodash'
import useTextMessages from 'admin/hooks/useTextMessages'
import useDocument from 'admin/hooks/useDocument'
import useOrganisation from 'admin/hooks/useOrganisation'
import Pagination from 'admin/components/ui/Pagination'
import Table from './Table'
import InputField from 'admin/components/ui/InputField'
import FourZeroFour from 'admin/components/FourZeroFour'
import Modal from 'admin/components/ui/Modal'
import TextMessage from 'admin/components/TextMessage'
import Page from 'admin/components/Page'

export default () => {
  const {organisationID} = useParams()
  const {textMessages, totalCount, filters, setFilters} = useTextMessages(organisationID)
  const {organisation} = useOrganisation(organisationID)
  const [textMessageModal, setTextMessageModal] = useState(null)

  useDocument({title: 'Text messages'})

  const handleSearch = changes => {
    setFilters({...changes, page: 1})
  }

  if (isEmpty(organisation)) return <Page />
  if (!organisation.smsEnabled) return <FourZeroFour />

  return (
    <Fragment>
      <Modal onClose={() => setTextMessageModal(null)} isOpen={textMessageModal}>
        <TextMessage textMessageId={textMessageModal} />
      </Modal>

      <Page
        id='admin_text_messages'
        title='Manage text messages'
        subtitle={`Showing ${textMessages.length} of ${pluralise(`submitted text message`, totalCount, true)}`}>
        <form className='box is-radiusless is-shadowless has-background-filters'>
          <div className='field is-grouped'>
            <div className='control is-expanded'>
              <InputField
                type='search'
                name='q'
                label='Search'
                autoComplete='off'
                value={filters.q}
                onChange={handleSearch}
              />
            </div>
          </div>
        </form>

        {textMessages.length != 0 ? (
          <Fragment>
            <div className='level'>
              <div className='level-left'></div>
              <div className='level-right'>
                <Pagination
                  page={filters.page}
                  limit={filters.limit}
                  total={totalCount}
                  onChange={setFilters}
                  className='level-item'
                />
              </div>
            </div>

            <Table data={textMessages} organisationID={organisationID} onClick={setTextMessageModal} />

            {textMessages.length >= 10 && (
              <div className='level'>
                <div className='level-left'></div>
                <div className='level-right'>
                  <Pagination
                    page={filters.page}
                    limit={filters.limit}
                    total={totalCount}
                    onChange={setFilters}
                    className='level-item'
                  />
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <p>No text messages</p>
        )}
      </Page>
    </Fragment>
  )
}
