import { gql } from 'admin/lib/apollo'
import React from 'react'
import SelectField from '../SelectField'

const QUERY = gql`
  query GetPlansForPlanField($organisationId: ID!) {
    plans(organisationId: $organisationId) {
      collection {
        id
        name
      }
    }
  }
`

export default ({buttonText, onChange, errors}) => (
  <SelectField
    id='payment-detail-select'
    buttonText={buttonText || 'Select plan'}
    errors={errors}
    onChange={onChange}
    dataQuery={QUERY}
    dataField='plans'
  />
)